import React, { useState } from 'react';
import twitterLogo from '../assets/twitter.png';
import githubLogo from '../assets/github.png';
import linkedinLogo from '../assets/linkedin.png';

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const mailtoLink = `mailto:contact@wyza.uk?subject=Contact Form Submission from ${formData.name}&body=${formData.message}%0D%0A%0D%0AFrom: ${formData.name} (${formData.email})`;

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = mailtoLink;
  };

  return (
    <div className="get-in-touch-container">
      <form className="contact-form" onSubmit={handleSubmit}>
        <h2>Contact Us</h2>
        {/* Social Media Icons */}
        <div className="social-icons-container">
          <a href="https://twitter.com/andytwelves" target="_blank" rel="noopener noreferrer">
            <img src={twitterLogo} alt="Twitter" className="social-icon" />
          </a>
          <a href="https://github.com/wyza-data" target="_blank" rel="noopener noreferrer">
            <img src={githubLogo} alt="GitHub" className="social-icon" />
          </a>
          <a href="https://www.linkedin.com/company/wyza/" target="_blank" rel="noopener noreferrer">
            <img src={linkedinLogo} alt="LinkedIn" className="social-icon" />
          </a>
        </div>
        <h3>Bring Data Into Your Campaign</h3>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Your Name"
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Your Email"
          required
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Your Message"
          required
        />
        <button type="submit" className="email-link">Send</button>
      </form>
    </div>
  );
};

export default GetInTouch;