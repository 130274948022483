import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import PublicProjects from './components/PublicProjects';
import WorkedWith from './components/WorkedWith';
import WhoWeAre from './components/WhoWeAre';
import GetInTouch from './components/GetInTouch';
import Footer from './components/Footer';
import { initTheme, listenForThemeChanges } from './utils/theme';

function App() {
  useEffect(() => {
    initTheme(); // Initialize the theme when the component mounts
    listenForThemeChanges(); // Listen for system theme changes
  }, []);

  return (
    <div className="App">
      <Navbar />
      <main>
        <HeroSection />
        <PublicProjects />
        <WorkedWith />
        {/* <WhoWeAre /> */}
        <GetInTouch />
        <Footer />
      </main>
    </div>
  );
}

export default App;