import React from 'react';
// Import images
import voteSpeakerImage from '../assets/votespeaker.png';
import surveyImage from '../assets/survey.png';
import parliamentImage from '../assets/parliament.png';
import ballotImage from '../assets/ballot.png';

const PublicProjects = () => {
  const projects = [
    {
      id: 1,
      title: 'VoteSpeaker',
      imageUrl: voteSpeakerImage,
      link: 'https://votespeaker.vercel.app',
      description: 'A platform for enhancing voter engagement through personalised letters.'
    },
    {
      id: 2,
      title: 'Electoral Commission Website ChatBot',
      imageUrl: ballotImage,
      link: 'https://vectorchat.wyza.uk',
      description: 'Have personable conversations with a wealth of useful documents, and be pointed right to the source.'
    },
    {
      id: 3,
      title: 'Text Survey AI',
      imageUrl: surveyImage,
      link: 'mailto:jethro@wyza.uk',
      description: 'Innovative survey tool to keep constituents engaged and passively keep track of popular issues.'
    },
    {
      id: 4,
      title: 'Right Honourable Chatter',
      imageUrl: parliamentImage,
      link: 'https://whatsard.web.app',
      description: 'Engage in Parliamentary discussion the modern way.'
    }
  ];

  return (
    <section id="publicProjects" className="public-projects-section">
      <hr className="hr-style" />
      <div className="who-we-are-column" style={{ display: 'flex', alignItems: 'flex-start', margin: '20px 0' }}>
        <h2 className="section-heading" style={{ flexBasis: '50%', textAlign: 'left' }}>Public Projects</h2>
        <h3 className="section-subheading" style={{ flexBasis: '50%', textAlign: 'left'}}>Try our open tools to understand what we can do for you.</h3>
      </div>
      <div className="projects-container">
        {projects.map((project) => (
          <a href={project.link} target="_blank" rel="noopener noreferrer" key={project.id} className="project">
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <img src={project.imageUrl} alt={project.title} className="project-image" />
          </a>
        ))}
      </div>
    </section>
  );
};

export default PublicProjects;