import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import banner from '../assets/banner.png';

const Navbar = () => {
  const [navBackground, setNavBackground] = useState('navbar');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (show) {
        setNavBackground('navbar navbar-scrolled');
      } else {
        setNavBackground('navbar');
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <>
      <nav className={navBackground}>
        <div className="hamburger" onClick={toggleSidebar}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="logo">
          <img src={banner} alt="Wyza logo" />
        </div>
        <ul className={`nav-links ${sidebarOpen ? 'open' : ''}`}>
          <li>
            <Link to="publicProjects" smooth={true} duration={1000}>
              Public Projects
            </Link>
          </li>
          <li>
            <Link to="workedWith" smooth={true} duration={1000}>
              Who We've Worked With
            </Link>
          </li>
          <li>
            <Link to="whoWeAre" smooth={true} duration={1000}>
              Who we are
            </Link>
          </li>
        </ul>
      </nav>
      {/* Overlay */}
      {sidebarOpen && <div className="overlay" onClick={closeSidebar}></div>}
    </>
  );
};

export default Navbar;