import React from 'react';

const Footer = () => {
  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      <div className="hr-footer-container">
        <hr className="hr-footer-style" />
      </div>
      <div className="footer-container">
        <div className="footer-column logo-column">
          <p>Wyza, 2024</p>
          <address>Newspeak House<br />London, E2 7DG</address>
        </div>
        <div className="footer-column back-to-top-column">
          <a href="#top" className="back-to-top-link" onClick={scrollToTop}>Back to top ↑</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;