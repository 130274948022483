import React, { useRef, useEffect, useState } from 'react';
import civitasImage from '../assets/civitas.png';
import parlyworkflowstudyImage from '../assets/parlyworkflowstudy.png';
import campaignlabImage from '../assets/campaignlab.png';

const WorkedWith = () => {
  const companies = [
    { id: 1, imageUrl: civitasImage, name: 'Civitas', link: 'https://www.wearecivitas.com/' },
    { id: 2, imageUrl: campaignlabImage, name: 'Campaign Lab', link: 'https://www.campaignlab.uk/' },
    { id: 3, imageUrl: parlyworkflowstudyImage, name: 'Parliament Workflow Study', link: 'https://parliamentworkflowstudy.org/' }
  ];

  const [allCompanies, setAllCompanies] = useState([...companies]);
  const containerRef = useRef(null);

  useEffect(() => {
    const updateCompaniesBasedOnViewport = () => {
      const currentCompaniesCount = window.innerWidth > 768 ? companies.length : companies.length * 3;
      // Only update state if the number of companies has changed
      if (allCompanies.length !== currentCompaniesCount) {
        setAllCompanies(window.innerWidth > 768 ? [...companies] : [...companies, ...companies, ...companies]);
      }
    };

    // Call the function initially and also on window resize
    updateCompaniesBasedOnViewport();
    window.addEventListener('resize', updateCompaniesBasedOnViewport);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', updateCompaniesBasedOnViewport);
    };
  }, [allCompanies.length, companies]); // Now depends on allCompanies.length and companies

  useEffect(() => {
    const container = containerRef.current;
    let requestID;

    const autoScroll = () => {
      if (container) {
        const maxScrollLeft = container.scrollWidth - container.clientWidth;
        if (container.scrollLeft >= maxScrollLeft - 1) {
          container.scrollLeft = 0;
        } else {
          container.scrollLeft += 1;
        }
        requestID = requestAnimationFrame(autoScroll);
      }
    };

    // Only start the auto-scroll if there are duplicated companies (for narrower viewports)
    if (allCompanies.length > companies.length) {
      requestID = requestAnimationFrame(autoScroll);
    }

    return () => {
      if (requestID) {
        cancelAnimationFrame(requestID);
      }
    };
  }, [allCompanies, companies.length]);

  return (
    <section id="workedWith" className="worked-with-section">
      <hr className="hr-style" />
      <div className="who-we-are-column" style={{ display: 'flex', alignItems: 'flex-start', margin: '20px 0' }}>
        <h2 className="section-heading" style={{ flexBasis: '50%', textAlign: 'left' }}>Who We've Worked With</h2>
        <h3 className="section-subheading" style={{ flexBasis: '50%', textAlign: 'left'}}>We partner with experienced organisations at the intersection of campaign strategy and technology.</h3>
      </div>
      <div className="worked-with-container" ref={containerRef}>
        {allCompanies.map((company, index) => (
          <div key={index} className="worked-with-company">
            <a href={company.link} target="_blank" rel="noopener noreferrer">
              <img src={company.imageUrl} alt={company.name} />
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WorkedWith;