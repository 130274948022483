// src/utils/theme.js

// Function to detect the system's theme preference
export const getSystemTheme = () => {
  if (!window.matchMedia) return 'light'; // Fallback for browsers that don't support window.matchMedia

  // Check if the system prefers dark mode
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return prefersDark ? 'dark' : 'light';
};

// Function to apply the theme to the document body
export const applyTheme = (theme) => {
  const root = document.documentElement; // Get the root element of the document

  // Remove existing theme classes
  root.classList.remove('light', 'dark');

  // Add the new theme class
  root.classList.add(theme);
};

// Initialize theme on load
export const initTheme = () => {
  const systemTheme = getSystemTheme(); // Get the system's theme preference
  applyTheme(systemTheme); // Apply the theme
};

// Listen for system theme changes
export const listenForThemeChanges = () => {
  window.matchMedia('(prefers-color-scheme: dark)').addListener((e) => {
    const newTheme = e.matches ? 'dark' : 'light';
    applyTheme(newTheme);
  });
};
