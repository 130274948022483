import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import backgroundVideo from '../assets/backgroundVideo.mp4';

const HeroSection = () => {
  const fullText = 'Make your campaign wiser with data & AI';
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      if (index < fullText.length) {
        setDisplayedText((prev) => prev + fullText.charAt(index));
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 50); // Adjust typing speed as needed

    return () => clearInterval(intervalId);
  }, [fullText]);

  return (
    <section className="hero-section" style={{ position: 'relative', height: '100vh' }}>
      <video autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: '0', left: '0' }}>
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)' }}></div>
      <div className="hero-content" style={{ position: 'absolute', bottom: '30%', width: '100%', display: 'flex', justifyContent: 'left' }}>
        <h1>{displayedText}</h1>
      </div>
      <div className="hero-content button-container" style={{ position: 'absolute' }}>
        <Link to="publicProjects" smooth={true} duration={1000}>
          <button style={{ padding: '10px 20px', fontSize: '2rem', cursor: 'pointer' }}>Learn more</button>
        </Link>
      </div>
    </section>
  );
};

export default HeroSection;